import React from "react";
import "../../Styles/Client.css";
import logo1sandvik from "../../Images/logo1sandvik.png";
import logo2SHCprojects from "../../Images/logo2SHCprojects.jpg";
import logo3shivganga from "../../Images/logo3shivganga.png";
import logo4KSBlimited from "../../Images/logo4KSBlimited.png";
import logo5TJN from "../../Images/logo5TJN.jpg";
import logo6Asahi from "../../Images/logo6Asahi.png";
import logo7shapoorji from "../../Images/logo7shapoorji.png";
import logo8riviera from "../../Images/logo8riviera.png";
import logo9Maden from "../../Images/logo9Maden.png";
import logo10panasonic from "../../Images/logo10panasonic.jpg";

const Client = () => {
  return (
    <>
    <h1 className="clientHead">Our Esteemed Clients</h1>
      <div className="container px-4 client">
        <div className="client-logo" >
          <img alt="Maden" src={logo9Maden} width={230} />
        </div>
        <div className="client-logo" >
          <img alt="sandvik" src={logo1sandvik} width={180} />
        </div>
        <div className="client-logo" >
          <img alt="KSBlimited" src={logo4KSBlimited} width={200} />
        </div>
        <div className="client-logo" >
          <img alt="panasonic" src={logo10panasonic} width={200} />
        </div>
        <div className="client-logo" >
          <img alt="Asahi" src={logo6Asahi} width={200} />
        </div>
        <div className="client-logo" >
          <img alt="logo" src={logo7shapoorji} width={200} />
        </div>
        <div className="client-logo" >
          <img alt="logo" src={logo3shivganga} width={200} />
        </div>
        <div className="client-logo">
          <img alt="logo" src={logo2SHCprojects} width={200} />
        </div>
        <div className="client-logo" >
          <img alt="logo" src={logo8riviera} width={200} />
        </div>
        <div className="client-logo" >
          <img alt="logo" src={logo5TJN} width={200} />
        </div>
      </div>
    </>
  );
};

export default Client;

import React from "react";
import EXENLOGO from "../../Images/EXENLOGO.png";
import "../../Styles/UrasBrand.css";
import { Link } from "react-router-dom";
import { ExenProductList } from "../DB/ExenProductDb.js";

const ExenBrand = () => {
  return (
    <>
      <div className="uras-main-container">
      <nav style={{ width:"100%", padding:"10px", display:"flex", justifyContent:"flex-end"}} aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a style={{color:"red", textDecoration:"none"}} href="/products/mitra-enterprises">MITRA ENTERPRISES</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            <a style={{color:"red", textDecoration:"none"}} href="/products/uras-products">URAS TECHNO</a>
            </li>
            <li className="breadcrumb-item">
              <a style={{color:"red", textDecoration:"none"}} href="/products/nippon-products">NMI</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            <a style={{color:"red", textDecoration:"none"}} href="/products/sakai-products">SAKAI</a>
            </li>
          </ol>
        </nav>
        <div className="uras-logo-container">
          <img alt="imagesss" src={EXENLOGO} width={150} />
        </div>

        <div className="brand-container">
          <div className="product-info-container">
            {ExenProductList.map((data) => {
              return (
                <div key={data.id} className="uras-vibrator-container">
                  <div className="vibrator-image">
                    <Link to={data.URL} target="_blank">
                      <img alt="imagesss"
                        src={data.image}
                        width={220}
                        height={240}
                        style={{ border: "1px solid gray" }}
                      />
                    </Link>
                  </div>
                  <div className="vibrator-content">
                    <Link className="link" to={data.URL} target="_blank">
                      <span className="titletag">{data.title}</span>
                    </Link>
                    <br />
                    <p>{data.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="video-container">
            <div>
              <span className="vdo-head">Videos</span>
            </div>
            <div className="onscrolldiv">
              <div className="vdo-cards">
                <strong>1. EXEN - Pneumatic Knocker</strong>
                <iframe
                  className="video"
                  width="300"
                  height="200"
                  src="https://www.youtube.com/embed/dBsij13yVWY?si=FROPVCAHUbnMDbdB"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>

              <div className="vdo-cards">
                <strong>2. Exen - knocker operation</strong>
                <iframe
                  className="video"
                  width="300"
                  height="200"
                  src="https://www.youtube.com/embed/o5XZHXrMcXI?si=K5oIuhu_CITzBBLo"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>

              <div className="vdo-cards">
                <strong>3. Exen slab concrete vibrator</strong>
                <iframe
                  className="video"
                  width="300"
                  height="200"
                  src="https://www.youtube.com/embed/rjwVqUdPbsQ?si=jC5nliypMX59TZ-x"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>

              <div className="vdo-cards">
                <strong>
                  4.boiler tube cleaning BCV Series
                </strong>
                <iframe
                  className="video"
                  width="300"
                  height="200"
                  src="https://www.youtube.com/embed/q2J6v3I1O98?si=eAqsZc56bhoYfn0P"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>

              <div className="vdo-cards">
                <strong>5. EXEN Direct Blaster video</strong>
                <iframe
                  className="video"
                  width="300"
                  height="200"
                  src="https://www.youtube.com/embed/1SDlDe_7K8k?si=5HEiy8UdjZ3riORp"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>

              <div className="vdo-cards">
                <strong>6. Viback</strong>
                <iframe
                  width="300"
                  height="200"
                  src="https://www.youtube.com/embed/KIYu3qcpvSA?si=CE0np1d71qCiriwL"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExenBrand;

import React from "react";
import { Link } from "react-router-dom";
import uras from "../../Images/UTC_logo.jpg";
import "../../Styles/UrasBrand.css";
import { UrasProductList } from "../DB/UrasProductdb";

const UrasBrand = () => {
  return (
    <>
      <div className="uras-main-container">
        <nav style={{ width:"100%", padding:"10px", display:"flex", justifyContent:"flex-end"}} aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a style={{color:"red", textDecoration:"none"}} href="/products/mitra-enterprises">MITRA ENTERPRISES</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
            <a style={{color:"red", textDecoration:"none"}} href="/products/exen-products">EXEN</a>
            </li>
            <li class="breadcrumb-item">
              <a style={{color:"red", textDecoration:"none"}} href="/products/nippon-products">NMI</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
            <a style={{color:"red", textDecoration:"none"}} href="/products/sakai-products">SAKAI</a>
            </li>
          </ol>
        </nav>

        <div className="uras-logo-container">
          <img alt="eeimage" src={uras} width={150} />
        </div>

        <div className="brand-container">
          <div className="product-info-container">
            {UrasProductList.map((data) => {
              return (
                <div key={data.id} className="uras-vibrator-container">
                  <div className="vibrator-image">
                    <Link to={data.URL} target="_blank">
                      <img alt="eeimage" src={data.image} width={220} />
                    </Link>
                  </div>
                  <div className="vibrator-content">
                    <Link className="link" to={data.URL} target="_blank">
                      <span className="titletag">{data.title}</span>
                    </Link>
                    <br />
                    <p>{data.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="video-container">
            <div>
              <span className="vdo-head">Videos</span>
            </div>
            <div className="onscrolldiv">
              <div>
                <iframe
                  width="300"
                  height="200"
                  src="https://www.youtube.com/embed/8S3bRS0clwM?si=rezzNxEn0tdtZXzA"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div>
                <iframe
                  width="300"
                  height="200"
                  src="https://www.youtube.com/embed/xf7c_CWz_Zg?si=JiD0S3f6Beqv1Ob1"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UrasBrand;
import React from "react";
import { CiDeliveryTruck } from "react-icons/ci";
import { AiOutlineSolution } from "react-icons/ai";
import "../../Styles/Home.css";
import { ArrowSvg } from "../../Common/IconSvg";

const ServiceProvide = () => {
  return (
    <section className="bg-light my-4 py-md-5 py-xl-8">
      <div className="container-fluid overflow-hidden px-4">
        <div className="row gy-4 gy-md-5 gy-lg-0 align-items-center">
          <div className="col-12 col-lg-5">
            <div className="row">
              <div className="col-12 col-xl-11">
                <h2 className="mb-3 mb-xl-4 serv-h2">What We Do?</h2>
              
                <p className="mb-3 mb-xl-4 serv-p">
                  Mitra Enterprises specializes in two key verticals: Trading
                  and Manufacturing. In the Trading vertical, we are the
                  authorized distributor for Uras Techno and Nippon Magnetics
                  Inc. across India, Nepal, Bhutan, Bangladesh, Sri Lanka,
                  Philippines and the Middle East. We also distribute products
                  from Cleveland Vibrator Company (CVC) - USA and Exen
                  Corporation - Japan. Our Manufacturing vertical offers a
                  diverse range of products, including silos, hoppers, tanks,
                  pneumatic conveying systems, automatic bag slitting machines,
                  screw conveyors, dust collectors, pressure vessels, mixers,
                  and industrial control panels and automation. We are committed
                  to providing high-quality solutions to industries worldwide.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-7 overflow-hidden">
            <div className="row gy-4">
              <div className="col-12 col-sm-6">
                <div className="card border-0 border-bottom border-danger shadow-sm">
                  <div className="card-body text-center p-4 p-xxl-5">
                    <CiDeliveryTruck className="delivery-icon" />

                    <h4 className="mb-4">Industrial Equipment Distribution</h4>
                    <p
                      className="mb-4 text-secondary"
                      style={{ textAlign: "justify" }}
                    >
                      Providing a range of industrial vibrators, electromagnetic
                      hammers, Pneumatic Knockers, Concrete Vibrators and other
                      high-quality equipment.
                    </p>
                    <a
                      href="/contact"
                      className="fw-bold text-decoration-none link-danger"
                    >
                      Learn More
                      <ArrowSvg />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="card border-0 border-bottom border-danger shadow-sm">
                  <div className="card-body text-center p-4 p-xxl-5">
                    <AiOutlineSolution className="solution-icon" />
                    <h4 className="mb-4">Manufacturing Solutions</h4>
                    <p
                      className="mb-4 py-3 text-secondary"
                      style={{ textAlign: "justify" }}
                    >
                      Offering silos, silo accessories, screw conveyors,
                      blowers, and more for efficient storage and handling of
                      bulk materials.
                    </p>
                    <a
                      href="/contact"
                      className="fw-bold text-decoration-none link-danger"
                    >
                      Learn More
                      <ArrowSvg />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceProvide;

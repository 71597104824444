import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Pages/HomePages/Home";
import Contact from "./Pages/Contact";
import Footer from "./Pages/Footer";
import Nav from "./Pages/Headers/Nav";
import Services from "./Pages/Services";
import Brands from "./Pages/Brands/Brands";
import UrasBrand from "./Pages/Brands/UrasBrand";
import ExenBrand from "./Pages/Brands/ExenBrand";
import NipponBrand from "./Pages/Brands/NipponBrand";
import SakaiBrand from "./Pages/Brands/SakaiBrand";
import HashLoader from "react-spinners/HashLoader";
import Downloads from "./Pages/Downloads";
import MitraBrand from "./Pages/Brands/MitraBrand";
import About from "./Pages/AboutPages/About";

function App() {
  const [loading, setLoading] = useState("false");

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <>
      {loading ? (
        <div className="app-loader">
          <HashLoader
            color={"gray"}
            style={{
              margin:"50% 50%"
            }}
            loading={true}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <div className="App">
            <header className="App-header">
              <ToastContainer />
              <Nav />
              <div style={{
                marginTop:"100px"
              }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/products" element={<Brands />} />
                <Route path="/products/uras-products" element={<UrasBrand />} />
                <Route path="/products/exen-products" element={<ExenBrand />} />
                <Route
                  path="/products/nippon-products"
                  element={<NipponBrand />}
                />
                <Route
                  path="/products/sakai-products"
                  element={<SakaiBrand />}
                />
                <Route
                  path="/products/mitra-enterprises"
                  element={<MitraBrand />}
                />
                <Route path="/downloads" element={<Downloads />} />
                <Route path="/services" element={<Services />} />
                <Route path="/contact" element={<Contact />} />
              </Routes>
              </div>
              <Footer />
              
            </header>
          </div>
        </>
      )}
    </>
  );
}

export default App;

import React from "react";
import Infopage from "./Infopage";
import ServiceProvide from "./ServiceProvide";
import ChooseUs from "./ChooseUs";
import Client from "./Client";
import "../../Styles/Home.css";
import Slider from "./Slider";

export default function Home() {
  return (
    <>
      <Slider />
      <Infopage />
      <ServiceProvide />
      <ChooseUs />
      <Client />
    </>
  );
}

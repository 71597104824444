import React from "react";

const Expertise = () => {
  return (
    <div>
      {/* <!-- About 2 - Bootstrap Brain Component -->  */}
      <section className="py-3 py-md-5">
        <div className="container-fluid">
          <div className="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
            <div className="col-12 col-lg-6">
              <img
                className="img-fluid rounded"
                loading="lazy"
                src="https://bootstrapbrain.com/demo/components/abouts/about-2/assets/img/about-img-1.webp"
                alt="About 2"
              />
            </div>
            <div className="col-12 col-lg-6">
              <div className="row justify-content-xl-center">
                <div className="col-12 col-xl-10">
                  <h2 className="mb-3 text-danger">Our Expertise</h2>
                  <p className="lead fs-5 mb-3 mb-xl-5">
                    Mitra Enterprises specializes in the distribution and
                    manufacturing of industrial equipment. Our product range
                    includes:
                  </p>
                  <div className="d-flex align-items-center mb-3">
                    <div className="me-3 text-danger">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        fill="currentColor"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg>
                    </div>
                    <div>
                      <p className="fs-5 m-0">
                        Industrial Vibrators and Vibratory Equipment:
                      </p>
                    </div>
                  </div>
                  <p className="text-justify">
                    Authorized distributor for Uras Techno - Japan, and supplier
                    of Electro Magnetic Hammers from Nippon Magnetics Inc. -
                    Japan. Air Knockers and Concrete Vibration Series from Exen
                    Corporation Japan. Light compaction equipment series from
                    Sakai-Japan
                  </p>

                  <div className="d-flex align-items-center mb-3">
                    <div className="me-3 text-danger">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        fill="currentColor"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg>
                    </div>
                    <div>
                      <p className="fs-5 m-0">Manufacturing Division:</p>
                    </div>
                  </div>
                  <p>
                    We produce high-quality silos, screw conveyors, blowers, bin
                    activators, feeders, and screens to meet the diverse needs
                    of our clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Expertise;

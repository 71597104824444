import React from "react";
import "../Styles/Footer.css";

function Footer() {
  return (
    <>
      <footer id="footer" className="footer position-relative light-background">
        <div className="container footer-top">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6 footer-about">
                <h4>Address</h4>
              <div className="footer-contact pt-3">
                <p>Office # 1/A, Kalyan Dipti C.H.S. </p>
                <p>Near Birla College, Beside Hotel Paradise</p>
                <p>Kalyan (W) 421 301, Maharashtra, India</p>
                <p className="mt-3">
                  <strong>Phone:</strong> <span>+91 77 10 89 83 73</span>
                </p>
                <p>
                  <strong>Email:</strong> <span>sales@concretemitra.com</span>
                </p>
              </div>
             
            </div>

            <div className="col-lg-2 col-md-3 footer-links">
              <h4>Main Sections</h4>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about">About us</a>
                </li>
                <li>
                  <a href="/products">Products</a>
                </li>
                <li>
                  <a href="/downloads">Downloads</a>
                </li>
                <li>
                  <a href="/services">Services</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-3 footer-links">
              <h4>Our Products</h4>
              <ul>
                <li>
                  <a href="/products/mitra-enterprises">Mitra Enterprises</a>
                </li>
                <li>
                  <a href="/products/uras-products">Uras Techno</a>
                </li>
                <li>
                  <a href="/products/nippon-products">Nippon Magnetics</a>
                </li>
                <li>
                  <a href="/products/exen-products">Exen Corporation</a>
                </li>
                <li>
                  <a href="/products/sakai-products">Sakai</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-12 footer-newsletter">
              <h4>Connect With Us</h4>
              <p>Get in touch with us for more details!</p>
              {/* social icon  */}
              <div className="social-links">
                <a
                  href="https://wa.me/917710898373?text=Hello%20Can%20we%20Connect?"
                  className="link-dark"
                  target="blank"
                >
                  <i className="bi bi-whatsapp"></i>
                </a>
                <a href="mailto:info@concretemitra.com?subject=Subject%20of%20the%20email&body=Body%20of%20the%20email">
                  <i className="bi bi-envelope-plus"></i>
                </a>
                <a
                  className="link-dark"
                  href="https://www.linkedin.com/in/nikhil-medhe-27146220"
                  target="blank"
                >
                  <i className="bi bi-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container copyright text-center mt-4">
          <p>
            © <span>Copyright</span>{" "}
            <strong className="px-1 sitename">2018 Company, Inc.</strong>
            <span>All Rights Reserved</span>
          </p>
          <div className="credits">
            Designed by{" "}
            <a href="https://bootstrapmade.com/">Mitra Enterprises</a>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;

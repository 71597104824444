import Feeders from "../../Images/Feeders.jpg";
import BulkerUnloading from "../../Images/BulkerUnloading.jpg";
import CementSilo from "../../Images/CementSilo.jpg";
import DustCollector from "../../Images/DustCollector.jpeg";
import IndustrialSilo from "../../Images/IndustrialSilo.jpg";
import screwconveyor from "../../Images/screwconveyor.jpg";
import binactivator from "../../Images/bin-activator.jpg";
import circularscreen from "../../Images/circularscreen.jpg";
import bigstorage from "../../Images/Big-Storage-Silo.jpg";

export const MitraProductList = [
  {
    id: 1,
    title: "Silos for Cement, Flyash, GGBS, and Microsilica",
    desc: 'Capacity: 20 Ton to 5000 Ton , Material: High-grade Carbon Steel (M.S.) , Types: Bolted Silo, Folding Silo, Full Fabricated Silo Our silos are designed to meet the stringent requirements of the construction industry, providing robust and efficient storage solutions for bulk materials like cement, flyash, GGBS, and microsilica. With various designs available, we ensure optimal performance and ease of installation.',
    image: CementSilo,
  },
  {
    id: 2,
    title: "Industrial Application Silos",
    desc: 'Capacity: 20 Ton to 5000 Ton , Materials: Mild Steel (MS), Stainless Steel (SS), Aluminium Our industrial silos cater to diverse sectors including pharmaceuticals, chemicals, mineral ores, petroleum products, cement, coal, carbon black, sawdust, and wood chips. Our designs include bolted silos, stainless steel silos, and specialized lime storage silos with anti-corrosive properties. Each silo is customized with sealed tops, ventilation systems, and outlet pipes to suit specific requirements.',
    image: IndustrialSilo,
  },
  {
    id: 3,
    title: "Big Storage Silos",
    desc: 'We specialize in the efficient management and storage of bulk materials, providing high-quality solutions for industries that need reliable storage for food and industrial materials. Our big storage silos are designed to maintain product integrity and optimize storage space, ensuring seamless operations for our clients.',
    image: bigstorage,
  },
  {
    id: 4,
    title: "Silo Accessories",
    desc: 'Dust Collectors , Butterfly Valves, Pressure Relief Valves, Fluidizers. To complement our silo solutions we offer a comprehensive range of accessories to enhance functionality and ensure smooth operation.',
    image: DustCollector,
  },
  {
    id: 5,
    title: "Screw Conveyors",
    desc: 'Materials: Mild Steel (M.S), Stainless Steel (S.S) Our screw conveyors are engineered for efficient material handling, offering reliable performance in various industrial applications.',
    image: screwconveyor,
  },
  {
    id: 6,
    title: "Blowers",
    desc: 'Types: Twin Roots Blower, Tri-lobe Roots Blower for Bulker Unloading System , Brands: Wing Compressor by Hori Engineering for Bulkers, Bulk Powder Conveying Systems. Our blowers are designed to deliver high efficiency and reliability, catering to a wide range of industrial needs.',
    image: BulkerUnloading,
  },
  {
    id: 7,
    title: "Bin Activators",
    desc: 'Our bin activators ensure smooth material flow and prevent blockages, enhancing the efficiency of your storage and handling systems.',
    image: binactivator,
  },
  {
    id: 8,
    title: "Feeders",
    desc: 'We offer a variety of feeders to suit different material handling requirements, ensuring precise and consistent feed rates.',
    image: Feeders,
  },
  {
    id: 9,
    title: "Screens",
    desc: 'Our screens are designed for optimal performance in separating and sizing various materials, providing reliable solutions for industrial processes.',
    image: circularscreen,
  },
];

import React from "react";
import "../../Styles/Home.css";


const Infopage = () => {
  return (
    <div  style={{ marginTop: "10px" }}>
        <div
          className="container-fluid aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="row ">
            <div className="col-lg-6 order-1 order-lg-2">
              <img src="https://bootstrapmade.com/demo/templates/Gp/assets/img/about.jpg" className="img-fluid" alt="" />
            </div>
            <div className="col-lg-6 order-2 order-lg-1 content">
              <h1>Welcome To Mitra Enterprises</h1>

              <p style={{textAlign:"justify"}}>
                Mitra Enterprises specializes in providing top-quality
                industrial equipment and manufacturing solutions. Our commitment
                to innovation, reliability, and customer satisfaction sets us
                apart as your trusted partner in driving industrial excellence.
                <br />
                With more than 16 years of experience in the Industrial sector
                and more than 100 Years of experience of Partner Companies we
                set the benchmark for Precision, Quality and Reliability.
                Established in 2018, Mitra Enterprises has quickly become a
                leading distributor and manufacturer of industrial equipment.
                <br />
                Our dedication to quality and innovation has earned us a
                reputation for delivering cutting-edge solutions that optimize
                efficiency and productivity. We pride ourselves on our ability
                to deliver tailored solutions that address the unique
                requirements of our clients. Our team of professionals is
                dedicated to providing exceptional service, technical expertise,
                and reliable products that meet the highest standards of quality
                and performance.
                <br />
                As we continue to expand our reach and enhance our product
                portfolio, we remain committed to our core values of integrity,
                innovation, and customer focus. We look forward to serving you
                and contributing to your success in the industrial sector.
              </p>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Infopage;

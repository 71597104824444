import React from "react";
import "../Styles/Services.css";
import servimg1 from '../Images/servimg1.jpg'
import servimg2 from  '../Images/servimg2.jpg'
import servimg3 from  '../Images/servimg3.jpg'
import servimg4 from  '../Images/servimg4.jpg'
import servimg6 from  '../Images/servimg6.jpg'
import servimg5 from  '../Images/servimg5.jpg'

const Services = () => {
  return (
    <>
      <div className="services">
        <h2>What We Offer</h2>
        <p>
          At Mitra Enterprises, we offer a comprehensive range of services
          designed to meet the diverse needs of industries worldwide. Our
          expertise spans distribution, manufacturing, and innovative solutions
          to ensure your operations run smoothly and efficiently.
        </p>
        <h2> Sales Support Services </h2>
        <p>
          At Mitra Enterprises, we are committed to providing exceptional sales
          support to ensure our clients have a seamless and satisfying
          experience. Our team of dedicated professionals is here to assist you
          every step of the way, from initial inquiry to post-purchase support.
        </p>

        <div className="serrrr">
          <div className="service-card">
            <div className="service-image">
              <img alt="serviceimage" src={servimg1} height={260} width="100%"/>
            </div>
            <div className="service-content">
              <div className="alert alert-secondary" role="alert">
                <h5>Personalized Consultations</h5>
              </div>
              <p className="sub-title">
                Our sales team is available to provide personalized
                consultations to help you find the right solutions for your
                specific needs.
              </p>
              <div className="list-items">
                <li>
                  Needs Assessment: We take the time to understand your
                  requirements and challenges.
                </li>
                <li>
                  Product Recommendations: Based on your needs, we suggest the
                  most suitable products and services from our extensive
                  portfolio.
                </li>
                <li>
                  Customized Solutions: We offer tailored solutions to meet your
                  unique industrial requirements.
                </li>
              </div>
            </div>
          </div>

          <div className="service-card">
            <div className="service-content">
              <div className="alert alert-secondary" role="alert">
                <h5>Detailed Product Information</h5>
              </div>
              <p className="sub-title">
                We provide comprehensive information about our products to help
                you make informed decisions.
              </p>
              <div className="list-items">
                <li>
                  Product Specifications: Detailed technical specifications and
                  features of each product.
                </li>
                <li>
                  Applications and Benefits: Information on how our products can
                  enhance your operations and the benefits they offer.
                </li>
                <li>
                  Case Studies and Success Stories: Real-world examples of how
                  our products have helped other clients achieve their goals.
                </li>
              </div>
            </div>
            <div className="service-image">
            <img alt="serviceimage" src={servimg2} height={260} width="100%"/>
            </div>
          </div>

          <div className="service-card">
            <div className="service-image">
            <img alt="serviceimage" src={servimg3} height={260} width="100%"/>
            </div>
            <div className="service-content">
              <div className="alert alert-secondary" role="alert">
                <h5>Quotation and Ordering Assistance</h5>
              </div>
              <p className="sub-title">
                Our team is here to assist you with quotations and the ordering
                process to ensure a smooth and efficient experience.
              </p>
              <div className="list-items">
                <li>
                  Prompt Quotations: We provide timely and accurate quotations
                  based on your requirements.
                </li>
                <li>
                  Order Processing: Efficient handling of your orders to ensure
                  quick turnaround times.
                </li>
                <li>
                  Payment Options: Flexible payment options to suit your
                  financial needs.
                </li>
              </div>
            </div>
          </div>

          <div className="service-card">
            <div className="service-content">
              <div className="alert alert-secondary" role="alert">
                <h5>Delivery and Logistics Support</h5>
              </div>
              <p className="sub-title">
                We manage the logistics to ensure your orders are delivered on
                time and in perfect condition.
              </p>
              <div className="list-items">
                <li>
                  Shipping Coordination: Arranging shipping and handling to
                  deliver your products safely and on time.
                </li>
                <li>
                  Tracking and Updates: Providing regular updates on the status
                  of your order.
                </li>
                <li>
                  Customs and Documentation: Handling all necessary
                  documentation and customs requirements for international
                  shipments.
                </li>
              </div>
            </div>
            <div className="service-image">
            <img alt="serviceimage" src={servimg4} height={260} width="100%"/>
            </div>
          </div>

          <div className="service-card">
            <div className="service-image">
            <img alt="serviceimage" src={servimg5} height={260} width="100%"/>
            </div>
            <div className="service-content">
              <div className="alert alert-secondary" role="alert">
                <h5>After-Sales Support</h5>
              </div>
              <p className="sub-title">
                Our commitment to you doesn’t end with the sale. We offer
                comprehensive after-sales support to ensure your continued
                satisfaction.
              </p>
              <div className="list-items">
                <li>
                  Installation and Setup: Assistance with the installation and
                  setup of our products.
                </li>
                <li>
                  Training and Guidance: Training programs to help you and your
                  team get the most out of our products.
                </li>
                <li>
                  Maintenance and Repairs: Ongoing maintenance services and
                  prompt repairs to minimize downtime.
                </li>
                <li>
                  Technical Support: Expert technical support to address any
                  issues or questions you may have.
                </li>
              </div>
            </div>
          </div>

          <div className="service-card">
            <div className="service-content">
              <div className="alert alert-secondary" role="alert">
                <h5>Customer Feedback and Improvement</h5>
              </div>
              <p className="sub-title">
                We value your feedback and strive to continuously improve our
                services.
              </p>
              <div className="list-items">
                <li>
                  Feedback Channels: Easy-to-use channels for providing feedback
                  and suggestions.
                </li>
                <li>
                  Regular Follow-Ups: Follow-up calls and emails to ensure your
                  satisfaction with our products and services.
                </li>
                <li>
                  Continuous Improvement: Using your feedback to enhance our
                  offerings and support services.
                </li>
              </div>
            </div>
            <div className="service-image">
            <img alt="serviceimage" src={servimg6} height={260} width="100%"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;

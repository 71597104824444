import React from "react";
import "../../Styles/Brands.css";
import uras from "../../Images/UTC_logo.jpg";
import nippon from "../../Images/NMIlogo.png";
import exen from "../../Images/EXENLOGO.png";
import sakai from "../../Images/sakailogo.jpg";
import mitra from "../../Images/Mitra_Logo.jpg";
import { MdKeyboardArrowRight } from "react-icons/md";

const Brands = () => {
  return (
    <div className="brands">
      <div className="brands-main" id="brands">
        <div className="brand5">
          <div className="logo-container">
            <img alt="imagess" src={mitra} className="m-logo" />
          </div>
          <div className="brand-content">
            <li>Silos for Cement, Flyash, GGBS</li>
            <li>Silo Accessories</li>
            <li>Blowers - Wing Compressor</li>
            <li>Screw Conveyors</li>
            <li>Bin Activators </li>
          </div>
          <div className="Bttn">
            <a className="know-btn" href="/products/mitra-enterprises">
              Know More
              <MdKeyboardArrowRight size={20} style={{ marginBottom: "2px" }} />
            </a>
          </div>
        </div>

        <div className="brand1">
          <div className="logo-container">
            <img alt="imagess" src={uras} className="u-logo" />
          </div>
          <div className="brand-content">
            <li>Standard Uras Vibrators</li>
            <li>High Frequency Uras Vibrators</li>
            <li>Standard Single Phase Uras Vibrators</li>
            <li>Flange Mounted Uras Vibrators</li>
            <li>Vibratory and Electromagnetic Feeders</li>
          </div>
          <div className="Bttn">
            <a href="/products/uras-products" className="know-btn">
              Know More
              <MdKeyboardArrowRight size={20} style={{ marginBottom: "2px" }} />
            </a>
          </div>
        </div>

        <div className="brand2">
          <div className="logo-container">
            <img alt="imagess" src={exen} className="n-logo" />
          </div>
          <div className="brand-content">
            <li>Pneumatic Air Knocker</li>
            <li>Surface finish concrete vibrators</li>
            <li>Concrete vibrators</li>
            <li>Vibrator for boiler tube cleaning BCV Series</li>
            {/* <li>Exen-Viback Excavator Mounted Concrete Vibrators</li> */}
          </div>
          <div className="Bttn">
            <a className="know-btn" href="/products/exen-products">
              Know More
              <MdKeyboardArrowRight size={20} style={{ marginBottom: "2px" }} />
            </a>
          </div>
        </div>

        <div className="brand3">
          <div className="logo-container">
            <img alt="imagess" src={nippon} className="e-logo" />
          </div>
          <div className="brand-content">
            <li>MagHammer-Electromagnetic</li>
            <li>Electromagnetic Separator</li>
            <li>Electromagnetic Filter</li>
            <li>Suspended Tube Magnet</li>
          </div>
          <div className="Bttn">
            <a className="know-btn" href="/products/nippon-products">
              Know More
              <MdKeyboardArrowRight size={20} style={{ marginBottom: "2px" }} />
            </a>
          </div>
        </div>

        <div className="brand4">
          <div className="logo-container">
            <img alt="imagess" src={sakai} className="sakai-logo" />
          </div>
          <div className="brand-content">
            <li>Walk Behind Vibrating Roller</li>
            <li>Plate Compactor</li>
            <li>Reversible Plate Compactor</li>
            <li>Tamper</li>
          </div>
          <div className="Bttn">
            <a className="know-btn" href="/products/sakai-products">
              Know More
              <MdKeyboardArrowRight size={20} style={{ marginBottom: "2px" }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brands;

import Vibrator1 from "../../Images/Vibrator1.jpg";
import Vibrator2 from "../../Images/Vibrator2.jpg";
import Vibrator3 from "../../Images/Vibrator3.jpeg";
import Vibrator4 from "../../Images/Vibrator4.jpg";
import Vibrator5 from "../../Images/Vibrator5.jpg";

export const UrasProductList = [
  {
    id: 1,
    title: "standard uras vibrator",
    desc: `Long lasting with excelent vibration resistance, the base model Vibrator has the top market share in Japan. "The Uras Vibrator" consists of an electric motor with unbalanced weights attached to both ends of the shaft. It is a very simple and effective design which has been continuously improved for more than 50 years in order to minimize the harmful effects of vibration on the motor and maximize the service life. Uras Vibrators are reliable even in harsh environments such as deserts or at sea, and for use outdoors in all weather conditions.`,
    image: Vibrator1,
    URL: "https://www.uras-techno.co.jp/products/vibrators/standard_uras_vibrator/"
  },
  {
    id: 2,
    title: "High Frequency Uras Vibrators",
    desc: `A high-frequency vibration motor with a vibration frequency of 100 to 180 Hz, specialized for filling secondary concrete products. "Hi-Fre Euras" is a high-frequency series of Uras Vibrators developed for use in formwork for secondary concrete products. With a vibration frequency of 100Hz to 180Hz, it is possible to strongly compact concrete. It can be used for concrete products of various sizes, from tunnel shields to U-shaped gutters.`,
    image: Vibrator4,
    URL: "https://www.uras-techno.co.jp/products/vibrators/high_frequency_uras_vibrator/"
  },
  {
    id: 3,
    title: "Standard Single Phase Uras Vibrators",
    desc: `A vibration motor that can be powered by a single-phase household power source or a portable power source. The "Standard Single-Phase Uras Vibrator" is a Uras Vibrator that can generate powerful vibrations even with a single-phase household power source. Because it does not require a three-phase power source, it can be used in places other than factories. It can also be powered by a portable power source, making it suitable for a wide range of applications. It uses a capacitor-based starting method, allowing it to start smoothly with a small current.`,
    image: Vibrator2,
    URL: "https://www.uras-techno.co.jp/products/vibrators/standard_single_phase_uras_vibrator/"
  },
  {
    id: 4,
    title: "Flange Mounted Uras Vibrators",
    desc: `Flange Mount Vibrators create 3-dimensional vibration using only a single unit, particularly useful for circular screens.
           "The Flange Mount Uras Vibrator" was developed for use with circular screeners. The mass and relative angle of the upper and lower unbalanced weights can be adjusted to tune in the ideal 3-dimensional vibration for a circular screener or barrel polisher.`,
    image: Vibrator5,
    URL:"https://www.uras-techno.co.jp/en/products/vibrators/flange_mount_uras_vibrator/"
  },
  {
    id: 5,
    title: "Vibratory and Electromagnetic Feeders",
    desc: `Using the Uras Vibrator as a source of vibration, Uras Feeders provide powerful and reliable feeding and segmenting of material.
          Using two Uras Vibrators, "The Vibro Feeder" generates linear vibration, allowing it to uniformly feed granular materials. The Vibro Feeder is not affected by direct pressure from the hopper, is ideal for feeding sticky materials, and requires very little maintenance. Through the use of a frequency drive, the feeding speed can be adjusted. The vibro feeder can be used in any environment, including outdoors, clean room and sterile environments, and with flamable materials where there is risk of ignition.`,
    image: Vibrator3,
    URL:"https://www.uras-techno.co.jp/en/products/feeders/vibro_feeder/"
  },
];

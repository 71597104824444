import React from "react";
import whychoose from '../../Images/whychoose.jpg'

const ChooseUs = () => {
  return (
    <>
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
            <h1 className="mb-4 text-center choose-h1">
              Why Choose Us
            </h1>
            <hr className="w-50 mx-auto mb-3 mb-xl-9 border-dark-subtle" />
          </div>
        </div>
      </div>

      <div className="container-fluid justify-content-center px-4">
        <div className="row gy-4 gy-lg-0 align-items-center px-4">
          <div className="col-12 col-lg-6 ">
            <img
              className="img-fluid  border border-dark"
              loading="lazy"
              src={whychoose}
              alt="About 6"
            />
          </div>
          <div className="col-12 col-lg-6">
            <div className="row justify-content-xl-center">
              <div className="col-12 col-xl-11 mt-2">
                <div className="accordion accordion-flush" id="accordionAbout6">
                  <div className="accordion-item mb-4 border border-dark">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button bg-transparent fs-4 fw-bold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Quality Assurance
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionAbout6"
                    >
                      <div className="accordion-body">
                        All our products meet the highest standards of quality
                        and durability.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item mb-4 border border-dark">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed bg-transparent fs-4 fw-bold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Innovative Solutions
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionAbout6"
                    >
                      <div className="accordion-body">
                        We continuously innovate to provide cutting-edge
                        solutions.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item mb-4 border border-dark">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed bg-transparent fs-4 fw-bold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Customer Satisfaction
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionAbout6"
                    >
                      <div className="accordion-body">
                        We are committed to providing exceptional customer
                        service and support.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item mb-4 border border-dark">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed bg-transparent fs-4 fw-bold"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Global Reach
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionAbout6"
                    >
                      <div className="accordion-body">
                        Serving clients in India, Nepal, Bhutan, Bangladesh, Sri
                        Lanka, and the Middle East.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
  );
};

export default ChooseUs;

import React, { useState } from "react";
import axios from "axios";
import { PhoneInput } from "react-international-phone";
import ReactFlagsSelect from "react-flags-select";
import "react-international-phone/style.css";
import "react-phone-number-input/style.css";
import {toast} from 'react-toastify';
import "../Styles/Contact.css";

const Contact = () => {
  const [yname, setYname] = useState("");
  const [cname, setCname] = useState("");
  const [dept, setDept] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [selected, setSelected] = useState("");

  // handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!yname || !cname || !dept || !selected || !phone || !email || !msg) {
        toast.error("please provide all fields");
      }
      const res = await axios.post("/api/v1/mitra/sendEmail", {
        yname,
        cname,
        dept,
        selected,
        phone,
        email,
        msg,
      });
      console.log(res.data);

      //validation success
      if (res.data.success) {
        toast.success(res.data.message);
        setYname("");
        setCname("");
        setDept("");
        setSelected("");
        setPhone("");
        setEmail("");
        setMsg("");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      console.log(error.response.data);
    }
  };

  return (
    <div className="py-4">
      <section id="contact" className="contact section">
        <div
          className="container-fluid aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="roww" style={{
            display:"flex",
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center",
            gap:"20px"
          }}>
            <div className="col-lg-6">
              <div
                className="info-item d-flex flex-column justify-content-center align-items-center aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <i className="bi bi-geo-alt"></i>
                <h3>Address</h3>
                <p>
                Office # 1/A, Kalyan Dipti C.H.S.
                </p>
                <p>
                Near Birla College, Beside Hotel Paradise
                </p>
                <p>
                Kalyan (W) 421 301, Maharashtra, India
                </p>
              </div>
            </div>
            {/* <!-- End Info Item --> */}

            <div className="col-lg-6">
              <div
                className="info-item d-flex flex-column justify-content-center align-items-center aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <i className="bi bi-telephone"></i>
                <h3>Call Us</h3>
                <p>+91 77 10 89 83 73</p>
                <p>+91 80 97 60 97 18</p>
              </div>
            </div>
            {/* <!-- End Info Item --> */}

            <div className="col-lg-6">
              <div
                className="info-item d-flex flex-column justify-content-center align-items-center aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <i className="bi bi-envelope"></i>
                <h3>Email Us</h3>
                <p>sales@concretemitra.com</p>
              </div>
            </div>
            {/* <!-- End Info Item --> */}
          </div>

          
        </div>
      </section>
    </div>
  );
};

export default Contact;

import React from "react";
import { GiProcessor } from "react-icons/gi";
import "../../Styles/manu.css";

const ManufacturingExcellence = () => {
  return (
    <div>
      <div className="container-fluid manuclass px-4 py-2" id="featured-3">
        <h2 className="pb-2 border-bottom text-danger">
          Manufacturing Excellence
        </h2>
        <span>
          Our manufacturing vertical is dedicated to creating robust and
          efficient solutions for industrial storage and handling.
          <br /> We offer:
        </span>
        <div className="row rowws g-4 pt-5 ">
          <div className="feature col boxbox">
            <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-danger bg-gradient fs-2 mb-3">
              <GiProcessor />
            </div>

            <h3 className="fs-6 text-body-emphasis">
              Silos for Cement, Flyash, GGBS, and Microsilica:
            </h3>
            <p>
              Ranging from 20 to 5000 tons, made from high-grade carbon steel.
            </p>
          </div>
          <div className="feature col boxbox">
            <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-danger bg-gradient fs-2 mb-3">
              <GiProcessor />
            </div>
            <h3 className="fs-6 text-body-emphasis">
              Industrial Application Silos:
            </h3>
            <p>
              Suitable for pharmaceuticals, chemicals, mineral ores, petroleum
              products, and more.
            </p>
          </div>
        </div>

        <div className="row rowws g-4 pt-5">
          <div className="feature col boxbox">
            <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-danger bg-gradient fs-2 mb-3">
              <GiProcessor />
            </div>
            <h3 className="fs-6 text-body-emphasis">Big Storage Silos:</h3>
            <p>Designed for efficient management of bulk materials.</p>
          </div>
          <div className="feature boxbox col">
            <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-danger bg-gradient fs-2 mb-3">
              <GiProcessor />
            </div>
            <h3 className="fs-6 text-body-emphasis">
              Silo Accessories, Screw Conveyors, Blowers, Bin Activators,
              Feeders, and Screens:
            </h3>
            <p>Enhancing functionality and ensuring seamless operations.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManufacturingExcellence;

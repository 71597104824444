import walkbehindroller from "../../Images/walkbehindroller.png";
import Tamper from "../../Images/Tamper.png";
import reversibleplatecompactor from "../../Images/reversibleplatecompactor.png";
import platecompactor from "../../Images/platecompactor.png";

export const SakaiProductList = [
  {
    id: 1,
    title: "Walk Behind Vibrating Roller",
    desc: 'Used in Soil COmpaction, Improved Usability, Super silent (Sound power level : 94dB). One-touch hood cover for easy maintenance. High Efficiency , Small wall clearance and ample curb clearance allows the closest possible approach to any obstacles. All commands for operating the machine are centralized and can be made from the operation handle. Easy Maintenance , Easy water tank filling and draining is possible with a large diameter water cap and chained drain plug at the bottom.',
    image: walkbehindroller,
    URL: "https://www.sakainet.co.jp/en/products/light_equipments/"
  },
  {
    id: 2,
    title: "Plate Compactor",
    desc: 'High efficiency, Highly durable high tension steel plates assure long service life and perfect compaction on any job site. Safety provisions , Sakais original rubber shock-absorbers assure easy handling and comfortable operation. Hand-arm vibration is reduced by 30%. (For PC43,53,63). Easy maintenance , Bottom plate can be turned 180 degrees allowing even wear and prolonging the service life of the plate.',
    image: platecompactor,
    URL: "https://www.sakainet.co.jp/en/products/light_equipments/"
  },
  {
    id: 3,
    title: "Reversible Plate Compactor",
    desc: 'High efficiency, Newly designed body frame promises smooth and steady, compaction for big productivity. Hydrostatic operation lever assures easy and quick forward-reverse movements. Safety provisions, New protection frame protects the machine from any damages at job site or transportation. Easy maintenance, Fully sealed belt-cover protects belt from harmful gravels and assures long service life.',
    image: reversibleplatecompactor,
    URL: "https://www.sakainet.co.jp/en/products/light_equipments/"
  },
  {
    id: 4,
    title: "Tamper",
    desc: 'High efficiency, Compaction stroke is increased by 30% from earlier models.Tapered front corner shoe are standard equipment for protection of the side edge of pavement. Safety provisions , Sakais original large size rubber isolators assures easy control and comfortable operation. Easy maintenance, Multi air cleaner system promises long interval of engine maintenance.',
    image: Tamper,
    URL: "https://www.sakainet.co.jp/en/products/light_equipments/"
  },

];

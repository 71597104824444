import nmiVibra1 from "../../Images/nmiVibra1.jpg";
import nmiVibra2 from "../../Images/nmiVibra2.jpg";
import nmivibra3 from "../../Images/nmivibra3.jpg";


export const NipponProductList = [
  {
    id: 1,
    title: "Maghammer",
    desc: 'High efficiency for continuous impacting force, Flexible adjustments by controller fit specific needs for application, Sealed design allows use in severe dusty atmosphere, Operation can be activated by an external signal, internal timer or manual control.',
    image: nmiVibra1,
    URL: "https://www.nmi-jpn.com/en/products/maghammer/"
  },
  {
    id: 2,
    title: "suspended Tube Magnet",
    desc: 'Durable stainless pipe usable even in water, Guarantee of 15,000 gauss on pipe surface, Custom made of pipe length in 1mm increments, Inspection certificate with magnetic force for HACCP or ISO purpose upon request, Ion nitriding, tungsten thermal spray, Teflon coating on pipe surface options available, Eyebolt and grip options available.',
    image: nmivibra3,
    URL: "https://www.nmi-jpn.com/en/products/magnet-bar/magnet-bar1/"
  },
  {
    id: 2,
    title: "Electromagnetic Filter",
    desc: 'Strong Magnetic Flux Density (with screen set) over 16,000 gauss, Separation of any magnetic materials in wet/slurry conditions, Automated program reduces manpower.',
    image: nmiVibra2,
    URL: "https://www.nmi-jpn.com/en/products/electro-magnetic-filter/cs-x/"
  },
];

import React from "react";
import { Link } from "react-router-dom";
import sakai from "../../Images/sakailogo.jpg";
import "../../Styles/UrasBrand.css";
import { SakaiProductList } from "../DB/SakaiProductDb.js";

const SakaiBrand = () => {
  return (
    <>
      <div className="uras-main-container">
      <nav style={{ width:"100%", padding:"10px", display:"flex", justifyContent:"flex-end"}} aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a style={{color:"red", textDecoration:"none"}} href="/products/mitra-enterprises">MITRA ENTERPRISES</a>
            </li>
            <li className="breadcrumb-item">
              <a style={{color:"red", textDecoration:"none"}} href="/products/uras-products">URAS TECHNO</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            <a style={{color:"red", textDecoration:"none"}} href="/products/exen-products">EXEN</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            <a style={{color:"red", textDecoration:"none"}} href="/products/nippon-products">NMI</a>
            </li>
          </ol>
        </nav>
        <div className="uras-logo-container">
          <img alt="iiiimage" src={sakai} width={150} />
        </div>

        <div className="brand-container">
          <div className="product-info-container">
            {SakaiProductList.map((data) => {
              return (
                <div key={data.id} className="uras-vibrator-container">
                  <div className="vibrator-image">
                    <Link to={data.URL} target="_blank">
                      <img alt="iiiimage"
                        src={data.image}
                        width={220}
                        height={220}
                        style={{ border: "1px solid gray" }}
                      />
                    </Link>
                  </div>
                  <div className="vibrator-content">
                    <Link className="link" to={data.URL} target="_blank">
                      <span className="titletag">{data.title}</span>
                    </Link>
                    <br />
                    <p>{data.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="video-container">
            <div>
              <span className="vdo-head">Videos</span>
            </div>
            <div className="onscrolldiv">
              <div>
                <strong>1. Plate Compactor</strong>
                <iframe
                  width="300"
                  height="200"
                  src="https://www.youtube.com/embed/jQ8PZfKKcyI?si=0_ovOY03pCjtTXu7"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
              <div>
                <strong>2. Vibrating Roller - EV</strong>
                <iframe
                  width="300"
                  height="200"
                  src="https://www.youtube.com/embed/6zdyG5Fis9Q?si=haU49j_D18-YD_in"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SakaiBrand;

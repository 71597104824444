import React from "react";
import Statements from "./Statements";
import Expertise from "./Expertise";
import ManufacturingExcellence from "./ManufacturingExcellence";
import FourGroup from "./FourGroup";

const About = () => {
  return (
    <>
      {/* <!-- About 5 - Bootstrap Brain Component -->  */}
      <div className="py-md-2 py-xl-8 mb-0 pb-0 mt-6">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-10 col-lg-8">
              <h3 className="text-danger my-4">Welcome to Mitra Enterprises</h3>
              <p className="my-4">
                At Mitra Enterprises, we pride ourselves on being at the
                forefront of industrial innovation, providing top-tier solutions
                across various sectors since our inception in 2018. With a
                steadfast commitment to quality and customer satisfaction, we
                have established ourselves as a trusted name in the industry.
              </p>
            </div>
          </div>
        </div>
        <Statements/>
        <Expertise/>
        <ManufacturingExcellence/>
        <FourGroup/>
      </div>
    </>
  );
};

export default About;

import React from "react";
import "../../Styles/Fourgroup.css";
import { FaHandshakeAngle } from "react-icons/fa6";
import { IoIosBulb } from "react-icons/io";
import { FaUsers } from "react-icons/fa6";
import { MdOutlineContactPhone } from "react-icons/md";

const FourGroup = () => {
  return (
    <div className="container-fluid py-5">
      <div className="row gy-4">
        <div
          className="col-lg-4 col-md-6 aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="service-item position-relative">
            <div className="icon">
              <FaHandshakeAngle className="iconClass" />
            </div>
            <h3>Our Clients</h3>
            <p style={{ textAlign: "justify" }}>
              Our diverse client base spans across various industries, including
              construction, pharmaceuticals, chemicals, mining, automotive, and
              more. We are proud to serve clients in India, Nepal, Bhutan,
              Bangladesh, Sri Lanka, and the Middle East, ensuring that our
              high-quality solutions are accessible to industries worldwide.
            </p>
          </div>
        </div>

        <div
          className="col-lg-4 col-md-6 aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="service-item position-relative">
            <div className="icon">
              <IoIosBulb className="iconClass" />
            </div>
            <h3>Innovation and Quality</h3>
            <p style={{ textAlign: "justify" }}>
              At Mitra Enterprises, innovation is at the core of everything we
              do. We continuously invest in research and development to bring
              the latest advancements to our clients. Our commitment to quality
              ensures that every product we deliver meets the highest standards
              of durability and performance.
            </p>
          </div>
        </div>

        <div
          className="col-lg-4 col-md-6 aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="service-item position-relative">
            <div className="icon">
              <FaUsers className="iconClass" />
            </div>
            <h3>Customer-Centric Approach</h3>
            <p style={{ textAlign: "justify" }}>
              We believe in building strong, long-lasting relationships with our
              clients. Our customer-centric approach means that we listen to
              your needs, understand your challenges, and provide solutions that
              exceed your expectations. Your success is our success.
            </p>
          </div>
        </div>

        <div
          className="col-lg-4 col-md-6 aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          <div className="service-item position-relative">
            <div className="icon">
              <MdOutlineContactPhone className="iconClass" />
            </div>
            <h3>Join Us on Our Journey</h3>
            <p style={{ textAlign: "justify" }}>
              As we continue to grow and evolve, we invite you to join us on our
              journey. Whether you need industrial equipment, manufacturing
              solutions, or expert advice, Mitra Enterprises is here to support
              you every step of the way.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourGroup;

import airknocker3 from '../../Images/airknocker3.jpg'
import boilervibrator1 from '../../Images/boilervibrator1.png'
import ekca from '../../Images/ekca.png'
import e32dh from '../../Images/e32dh.jpg'
import flexibleneedlevibrator from '../../Images/flexibleneedlevibrator.jpg'
import handheldpunchvibrator from '../../Images/handheldpunchvibrator.png'
import shuttervibrator from '../../Images/shuttervibrator.png'
import slabvibrator from '../../Images/slabvibrator.png'
import viback from '../../Images/viback.jpg'
import concreteCutter from '../../Images/concreteCutter.jpeg'
import engindriven from '../../Images/engindriven.jpeg'
import BallVibrator from '../../Images/BallVibrator.jpg'
import TurbineVibrator from '../../Images/TurbineVibrator.gif'
import PistomVibrator from '../../Images/PistomVibrator.jpg'
import PneumaticBlaster from '../../Images/PneumaticBlaster.gif'

export const ExenProductList = [
  {
    id: 1,
    title: "Air knocker",
    desc: "The Air knocker series (direct impact type) are unique equipment in which the force of compressed air causes the piston to strike the hopper wall surface directly and energetically, which eliminate the adhesion or clogging of powder. In addition, this knocker is excellent in safety and economics because it can be used only by air supply, and it can be used safely for powder that has a characteristic to show adhesion when receiving the centrifugal force. While the indirect impact type strikes the mounted knocker base at a predetermined position, the air knocker is used in wider range of applications on your idea, such as striking the moving object and striking the target with the knocker itself moved. It is also used like the indirect impact type.",
    image: airknocker3,
    URL: "https://www.exen.co.jp/english/industry/prevention_knocker/RKD/index.php"
  },
  {
    id: 2,
    title: "Boiler Cleaning Vibrator",
    desc: "Reduce factory down time. This can allow to dramatically expand the cleaning range and improve cleaning efficiency. The vibrator for boiler tube removes dust adhered to the tube by giving strong vibration to the water tube. Since this vibrator's motor is a dual insulated and anti-vibration structure made of robust aluminum die cast, it can be used with confidence. This is a product specifically developed for cleaning a boiler tube, so it can not be used as a concrete vibrator.",
    image: boilervibrator1,
    URL: "https://www.exen.co.jp/english/industry/boiler_cleaning/BCV/index.php"
  },
  {
    id: 3,
    title: "Handy held external vibrator - Model EKCA",
    desc: "The handy held vibrator is a small, powerful concrete vibrator consisting of a vibrating unit connected directly to a motor. Suited mainly for secondary concrete products, along with stone masonry, test piece manufacture, and the like. Equipped with a robust double-insulated sealed motor made of die-cast aluminum, the unit weighs about 5 kg, being lightweight, coming in a wide variety of models. This makes the series of handy vibrators very easy to use on a wide range of worksites. The models available are the lightweight, user-friendly EKCA and the powerful EKD.",
    image: ekca,
    URL: "https://www.exen.co.jp/english/construction/const_work_vibrator/E-KC/index.php"
  },
  {
    id: 4,
    title: "Handy held scraper-type vibrator Model-E32DH",
    desc: "The handy scraper-type vibrator is a small, powerful concrete vibrator consisting of a vibrating unit connected directly to a motor. It transmits powerful vibration from the vibrating body to the scraper-shaped vibrating plate, thereby effectively producing thin products and surface finishes inaccessible to general round vibrators. Suited mainly for pouring concrete in secondary concrete products such as laggings, shelf plates, U-grooves, and other thin products. Equipped with a robust double-insulated sealed motor made of die-cast aluminum, the handy vibrator is lightweight and user-friendly.",
    image: e32dh,
    URL: "https://www.exen.co.jp/english/construction/const_work_vibrator/E-DH/index.php"
  },
  {
    id: 5,
    title: "flexible vibrator",
    desc: "The baby flexible vibrator is small and lightweight. Unlike the electrode type, the motor is connected to the vibrating unit by a rubber flexible hose. Even if the hose is bent somewhat during concreting, it can still remain useful. This flexible concrete vibrator (inner vibrator) can be used in a wide range of applications, including mainly residential foundation works, manufacturing of secondary products and test pieces, masonry, and the construction of retaining walls. This handy vibrator is easy to use on a wide range of worksites because it employs a robust aluminum die-cast double-insulated sealed motor, is lightweight, and comes in a wide range of models.",
    image: flexibleneedlevibrator,
    URL: "https://www.exen.co.jp/english/construction/const_work_vibrator/E-FP/index.php"
  },
  {
    id: 6,
    title: "Variable Frequency Concrete Shutter Vibrator",
    desc: "High-frequency vibration motors, although small and lightweight, produce powerful vibration at high rpm rates and display their full performance in concreting for not only in box culverts and concrete segments but L-shaped retaining walls, PC plates, and other large concrete products as well. These are also applicable to pouring a secondary layer for tunnels, subway works, shield works, and other projects.",
    image: shuttervibrator,
    URL: "https://www.exen.co.jp/english/construction/concrete_motor/HKM/index.php"
  },
  {
    id: 7,
    title: "Handy held punch vibrator E32DP & E32DPS",
    desc: "The handy held punch vibrator is a small, powerful concrete vibrator consisting of a vibrating unit connected directly to a motor. The powerful vibration of the vibrating body is transmitted to the vibration plate, so that the machine effectively defoams and surface-finishes slope faces where air bubbles are stubborn. The machine is particularly suited for pouring concrete into soil-retaining plates for secondary concrete products, shelf plates, U-shaped ditches, revetments, and the slope faces of civil engineering structures, and a wide range of other surfaces. Equipped with a robust double-insulated sealed motor made of die-cast aluminum, the handy held vibrator is lightweight and user-friendly.",
    image: handheldpunchvibrator,
    URL: "https://www.exen.co.jp/english/construction/const_work_vibrator/E-DP/index.php"
  },
  {
    id: 8,
    title: "Slab internal vibrator",
    desc: "The high-frequency 48 V series of slab internal vibrators are for the inside of concrete and designed specifically for slab concreting. These come in two models: CSV50 with a hose length of 1.5 mm (50 mm in nominal diameter) and a rubber head type that does not damage molds (CSV50-R, 1.5 m). In conventional practice, columns, walls, slabs (floors), and other regions were concreted with general-purpose vibrators. In slab concreting, in particular, workers tend to move over a wide range. Making vibrators lightweight therefore leads directly to a reduction in worker fatigue, resulting in increasing work efficiency dramatically.",
    image: slabvibrator,
    URL: "https://www.exen.co.jp/english/construction/const_work_freki/CSV/index.php"
  },
  {
    id: 9,
    title: "Viback Series - Excavator Mounted Concrete Pocker Vibrator",
    desc: "Best suited for concrete placement in cramped places and sand control dams inaccessible to hydraulic shovels. This product is used for the following applications:- Dam concrete placement",
    image: viback,
    URL: "https://www.exen.co.jp/english/construction/earthwork_dam/VB/index.php"
  },
  {
    id: 10,
    title: "Concrete Cutter",
    desc: "This Exen Semi-self-propelled concrete saw emphasizes cutting performance, durability, rust‐prevention, and safety. With the chassis of ECR14DC to 18DSC made of ductile cast iron, these products are machined with high precision, high rigidity, and low center of gravity to achieve stable operability and excellent cutting capacity. This concrete cutter achieves particularly high worker safety by being designed to meet adequately the safety requirements for JIS-compliant road works machinery. Also available are optional blades (for asphalt and concrete), diamond blades (P/C series), designed specifically for the ERC series.",
    image: concreteCutter,
    URL: "https://www.exen.co.jp/english/construction/cuter_drill_cutter/ERCDC/index.php"
  },
  {
    id: 11,
    title: "Engine Driven High Frequency Generator",
    desc: "This special-purpose generator is designed as a power supply unit for the high-frequency series of vibrators in city areas, mountainous areas, and other locations where commercial mains is unavailable or cannot be led in.",
    image: engindriven,
    URL: "https://www.exen.co.jp/english/construction/earthwork_dynamo/HAGMH/index.php"
  },
  {
    id: 12,
    title: "Piston Vibrator",
    desc: "Piston vibrator EPV series is a pneumatic vibrator that produces a stable vibration by vertical operation of piston. This is used mainly for prevention of blockage or filling of powder in hopper, tank, or piping chute, and for vibrating source of table vibrator, vibration conveying machine, and vibrating sorting machine. *Use a normally closed three-way valve for control of activation and stop.",
    image: PistomVibrator,
    URL: "https://www.exen.co.jp/english/industry/prevention_vibrator/EPV/index.php"
  },
  {
    id: 13,
    title: "Turbine Vibrator",
    desc: "Turbine vibrator is a pneumatic vibrator used in complete oil-free. Like the conventional ball vibrator, simply supplying compressed air into the turbine vibrator will easily generate vibration. （Lubrication is strictly prohibited to use for supply air) Because the turbine portion rotating at high speed does not contact with housing portion and generates vibration, the turbine vibrator produces very low level of noise and is friendly to the plant environment. The housing body, turbine, and air joint of turbine vibrator are items conforming to Food Sanitation Act, and other outer parts including muffler and cover bracket use stainless steel material. Therefore, the turbine vibrator can be used safely in food, pharmaceutic chemical and precision part industries. Turbine vibrators are available in a total of three models: model BTP of resin housing and model BTS of stainless steel (SUS304). All of joint, muffler, cover, etc. use stainless steel material. As equipment for preventing clogging or adhesion of powder, turbine vibrator is a flow aid product that is helpful for an area where minor vibration is required or for preventing clogging in hopper or chute",
    image: TurbineVibrator,
    URL: "https://www.exen.co.jp/english/industry/prevention_vibrator/BTP/index.php"
  },
  {
    id: 14,
    title: "Ball Vibrator",
    desc: "Ball vibrator is a compact vibrator that generates powerful centrifugal force vibration via high-speed rotation of steel ball by compressed air. This vibrator has simple principle and structure and can be used easily. It is generally used for preventing clogging in hopper chute, but the applications are expanded depending on ideas. Frequency and centrifugal force can be freely changed only by operation of air pressure. Depending on the on-site installation conditions, selection can be made from model lineup of BH-A, CH-A, and UH-A, which are different in positions of air supply and exhaust. (Supplying lubricant with an oiler is required)",
    image: BallVibrator,
    URL: "https://www.exen.co.jp/english/industry/prevention_vibrator/BH/index.php"
  },
  {
    id: 15,
    title: "Pneumatic Blaster",
    desc: "Pneumatic direct blaster eliminates a blockage by blasting the compressed air directly to the powder that caused adhesion or clogging. It has a large destruction force and is excellent in safety. It produces an effect especially on the powder that adheres when vibrated. Its operation is easy and multiple units can be operated simultaneously. The models are available in the tank capacity of 2.5L to 230L so that selection can be made depending on the size of tank and hopper, and the blockage situation.",
    image: PneumaticBlaster,
    URL: "https://www.exen.co.jp/english/industry/prevention_blaster/EDB/index.php"
  },
  
];

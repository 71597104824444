import React, { useEffect } from "react";
import homeImg1 from "../../Images/homeImg1.jpg";
import homeImg2 from "../../Images/homeImg2.jpg";
import homeImg3 from "../../Images/homeImg3.jpg";
import homeImg4 from "../../Images/homImg4.jpg";
import homeImg5 from "../../Images/homeImg5.jpg";
import homeImg6 from "../../Images/homeImg6.jpg";
import homeImg7 from "../../Images/homeImg7.jpg";

const Slider = () => {
  useEffect(() => {
    // Initialize the carousel on component mount
    const carousel = document.querySelector('#carouselExampleControls');
    new window.bootstrap.Carousel(carousel, {
      interval: 2000, // Auto slide interval in milliseconds (3 seconds)
      ride: 'carousel' // Ensure the carousel slides automatically
    });
  }, []);

  return (
    <div style={{ marginTop: "10px" }}>
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel" // Ensure auto slide behavior
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={homeImg1} className="d-block w-100" alt="homeImg" />
          </div>
          <div className="carousel-item">
            <img src={homeImg2} className="d-block w-100" alt="homeImg" />
          </div>
          <div className="carousel-item">
            <img src={homeImg3} className="d-block w-100" alt="homeImg" />
          </div>
          <div className="carousel-item">
            <img src={homeImg4} className="d-block w-100" alt="homeImg" />
          </div>
          <div className="carousel-item">
            <img src={homeImg5} className="d-block w-100" alt="homeImg" />
          </div>
          <div className="carousel-item">
            <img src={homeImg6} className="d-block w-100" alt="homeImg" />
          </div>
          <div className="carousel-item">
            <img src={homeImg7} className="d-block w-100" alt="homeImg" />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Slider;

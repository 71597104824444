import React from "react";

const Statements = () => {
  return (
    <div>
      <div className="container-fluid">
        <div className="row gy-3 gy-md-4 gy-lg-0">
          <div className="col-12 col-lg-6">
            <div className="card bg-light p-3 m-0 h-100">
              <div className="row gy-3 gy-md-0 align-items-md-center">
                <div className="col-md-5">
                <img
                    src="https://bootstrapbrain.com/demo/components/abouts/about-4/assets/img/about-img-1.jpg"
                    className="img-fluid rounded-start"
                    alt="Visionary Team"
                  />
                </div>
                <div className="col-md-7">
                  <div className="card-body p-0">
                    <h2 className="card-title text-danger mb-3">Our Mission</h2>
                    <p className="card-text">
                      Our mission is to deliver exceptional products and
                      services that enhance efficiency and productivity for our
                      clients. We strive to be the preferred partner for
                      industries worldwide by consistently providing reliable,
                      high-quality solutions tailored to meet their unique
                      needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="card bg-light p-3 m-0 h-100">
              <div className="row gy-3 gy-md-0 align-items-md-center">
                <div className="col-md-5">
                  <img
                    src="https://bootstrapbrain.com/demo/components/abouts/about-4/assets/img/about-img-1.jpg"
                    className="img-fluid rounded-start"
                    alt="Visionary Team"
                  />
                </div>
                <div className="col-md-7">
                  <div className="card-body p-0">
                    <h2 className="card-title text-danger mb-3">Our Vision</h2>
                    <p className="card-text text-justify">
                      We envision a future where Mitra Enterprises continues to
                      lead in industrial solutions, setting new standards for
                      excellence and innovation. Our goal is to expand our reach
                      globally, ensuring that our products and services
                      positively impact industries across all continents.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statements;

import React from "react";
import Mitra_Logo from "../../Images/Mitra_Logo.jpg";

const Nav = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light" 
      style={{
        padding:"15px 0px",
        position:"fixed",
        zIndex:"1000",
        width:"100%",
        top:"0",
        borderBottom:"0.3px solid #e3e3e3"
      }}
      
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src={Mitra_Logo} width={200} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <a className="nav-link  px-4" aria-current="page" href="/">
                HOME
              </a>
              <a className="nav-link px-4" href="/about">
                ABOUT US
              </a>
              <a className="nav-link px-4" href="/products">
                PRODUCTS
              </a>
              <a className="nav-link px-4" href="/downloads">
                DOWNLOADS
              </a>
              <a className="nav-link px-4" href="/services">
                SERVICES
              </a>
              <a className="nav-link px-4" href="/contact">
                CONTACT US
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Nav;

import React from "react";
import { Link } from "react-router-dom";
import Mitra from "../../Images/Mitra_Logo.jpg";
import "../../Styles/UrasBrand.css";
import { MitraProductList } from "../DB/MitraDb.js";

const MitraBrand = () => {
  return (
    <>
      <div className="uras-main-container">
      <nav style={{ width:"100%", padding:"10px", display:"flex", justifyContent:"flex-end"}} aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a style={{color:"red", textDecoration:"none"}} href="/products/uras-products">URAS TECHNO</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            <a style={{color:"red", textDecoration:"none"}} href="/products/exen-products">EXEN</a>
            </li>
            <li className="breadcrumb-item">
              <a style={{color:"red", textDecoration:"none"}} href="/products/nippon-products">NMI</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            <a style={{color:"red", textDecoration:"none"}} href="/products/sakai-products"> SAKAI </a>
            </li>
          </ol>
        </nav>
        <div className="uras-logo-container">
          <img alt="imagesss" src={Mitra} width={220} />
        </div>

        <div className="brand-container">
          <div className="product-info-container">
            {MitraProductList.map((data) => {
              return (
                <div key={data.id} className="uras-vibrator-container">
                  <div className="vibrator-image">
                  <Link to={data.URL} target="_blank">
                    <img alt="imagesss" src={data.image} 
                    width={220}
                    height={240}
                    style={{ border: "1px solid gray" }} />
                    </Link>
                  </div>
                  <div className="vibrator-content">
                  <Link className="link" to={data.URL} target="_blank">
                  <span className="titletag">{data.title}</span>
                  </Link>
                    <br />
                    <p>{data.desc}</p>
                   
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MitraBrand;

import React from "react";
import "../Styles/Downloads.css";
import { GrCatalog } from "react-icons/gr";
import urasCatalog1 from "../Docs/uras_Catalog1.pdf";
import sakai_catalog1 from "../Docs/sakai_catalog1.pdf";
import generalcatalog from "../Docs/general-catalog-NMI.pdf";
import maghammernmi from "../Docs/maghammer-en-nmi.pdf";
import catalogvibratorsexen from "../Docs/catalog_vibrators-exen.pdf";
import flowaid from "../Docs/catalog_flow_aid_systems.pdf";
import vibackcatalog from "../Docs/catalog_viback.pdf";
import vibackportrait from "../Images/viback_portrait.jpeg";
import UrasPortrait from "../Images/UrasPortrait.jpeg";
import sakaiPortrait from "../Images/sakaiPortrait.jpeg";
import ExenPortrait1 from "../Images/ExenPortrait1.jpeg";
import flowAidPortrait from "../Images/flowAidPortrait.jpeg";
import maghammerPortrait from "../Images/maghammerPortrait.jpeg";
import magneticSepPortrait from "../Images/magneticSepPortrait.jpeg";

const Downloads = () => {
  return (
    <div className="download">
      <div className="container py-4">
        <header className="pb-3 mb-4 border-bottom">
          <GrCatalog className="catalog-icon" />
          <span className="fs-4">Catalogues</span>
        </header>

        <div className="row align-items-md-stretch align-items-sm-start">
          <div className="col-md-4">
            <div className="catlogCard p-4 bg-body-tertiary border rounded-3">
              <h2 className="fs-4">Uras Techno Catalogue</h2>
              <a
                href={urasCatalog1}
                download="urasCatalog.pdf"
                className="btn btn-outline-secondary"
                type="button"
              >
                <img
                  className="img-fluid"
                  src={UrasPortrait}
                  alt="UrasPortrait"
                  width={200}
                  height={250}
                />
                Uras Catalogue
              </a>
            </div>
          </div>
          <div className="col-md-8">
            <div className="catlogCard p-4 bg-body-tertiary border rounded-3">
              <h2 className="fs-4">Exen Corporation Catalogue</h2>
              <div className="img-sectn d-flex flex-column flex-md-row justify-content-center align-items-center gap-4">
                <a
                  href={catalogvibratorsexen}
                  download="CatalogVibratorsExen.pdf"
                  className="btn btn-outline-secondary"
                  type="button"
                >
                  <img
                    className="img-fluid"
                    alt="ExenPortrait1"
                    src={ExenPortrait1}
                    width={200}
                    height={250}
                  />
                  Concrete Catalogue
                </a>

                <a
                  href={flowaid}
                  download="flowaid.pdf"
                  className="btn btn-outline-secondary"
                  type="button"
                >
                  <img
                    className="img-fluid"
                    alt="flowAidPortrait"
                    src={flowAidPortrait}
                    width={200}
                    height={250}
                  />
                  Flow Aid Catalogue
                </a>

                <a
                  href={vibackcatalog}
                  download="viback.pdf"
                  className="btn btn-outline-secondary"
                  type="button"
                >
                  <img
                    className="img-fluid"
                    alt="vibackportrait"
                    src={vibackportrait}
                    width={200}
                    height={250}
                  />
                  Viback Catalogue
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-6 mt-4">
            <div className="catlogCard p-4 bg-body-tertiary border rounded-3">
              <h2 className="fs-4">Nippon Magnetics Catalogue</h2>
              <div className="img-sectn d-flex flex-column flex-md-row justify-content-center align-items-center gap-4">
                <a
                  href={maghammernmi}
                  download="maghammer.pdf"
                  className="btn btn-outline-secondary"
                  type="button"
                >
                  <img
                    className="img-fluid"
                    alt="maghammerPortrait"
                    src={maghammerPortrait}
                    width={200}
                    height={250}
                  />
                  MagHammer Catalogue
                </a>
                <a
                  href={generalcatalog}
                  download="GeneralCatalogue.pdf"
                  className="btn btn-outline-secondary"
                  type="button"
                >
                  <img
                    className="img-fluid"
                    alt="magneticSepPortrait"
                    src={magneticSepPortrait}
                    width={200}
                    height={250}
                  />
                  Nippon Magnetics
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-4 mt-4">
            <div className="catlogCard p-4 bg-body-tertiary border rounded-3">
              <h2 className="fs-4">Sakai Catalogue</h2>
              <a
                href={sakai_catalog1}
                download="Sakai.pdf"
                className="btn btn-outline-secondary"
                type="button"
              >
                <img
                  className="img-fluid"
                  alt="sakaiPortrait"
                  src={sakaiPortrait}
                  width={200}
                  height={250}
                />
                Sakai Catalogue
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Downloads;
